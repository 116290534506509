export default {
  name: "ArticleDetailComponent",
  data() {
    return {
      texts: "",
      articleName: "",
      articleDescription: "",
      articleDetailArr: "",
      isMobile: false,
      isTablet: false,
      isFixed: false,
      isCenter: false,
      imgWidthResponive: false,
      rating: 4.5,
      loaded: false,
      imgSelected: "",
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    const img = new Image();
    img.src = this.imgSrc;
    img.addEventListener("load", () => {
      this.loaded = true;
    });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
    this.$store.commit("setMenuSidebar", { active: false });

    this.articleDetail();
  },
  methods: {
    onResize() {
      this.isFixed = 959 > window.innerWidth;
      this.isMobile = window.innerWidth < 600;
      this.isCenter = window.innerWidth > 600 && window.innerWidth < 960;
      this.isTablet = window.innerWidth > 959 && window.innerWidth < 1264;
    },
    // obtiene el detalle del articulo
    articleDetail() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.$route.params.sProductId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            // iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.articleDetailArr = response.data.results;

          if (this.articleDetailArr.aImages.length >= 1) {
            this.imgSelected = this.articleDetailArr.aImages[0].oImages.lg;
          }
          if (this.selectLanguage == "en") {
            this.articleName = response.data.results.aTranslations.find(
              (res) => {
                return res.sLang == "en";
              }
            ).sName;
            this.articleDescription = response.data.results.aTranslations.find(
              (res) => {
                return res.sLang == "en";
              }
            ).sDescription;
          } else if (this.selectLanguage == "sp") {
            this.articleName = response.data.results.aTranslations.find(
              (res) => {
                return res.sLang == "sp";
              }
            ).sName;
            this.articleDescription = response.data.results.aTranslations.find(
              (res) => {
                return res.sLang == "sp";
              }
            ).sDescription;
          } else {
            this.articleName = "undefined";
            this.articleDescription = "undefined";
          }
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    // manda al detalle del vendedor que se encuentra en el detalle del pedido de venta
    detailVendor: function(id) {
      this.$router.push(`/admin/vendor-detail/${id}`);
    },
    // muestra el dialog para eliminar el articulo
    deleteItemArticle(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.delete.title,
        descipcion: this.texts.delete.description,
        note: this.texts.delete.note,
        chkLabel: this.texts.delete.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/products/${item.sProductId}`,
        return: true,
        dobleConfirm: true,
      });
    },
    // obtiene la imagen seleccionaday la muestra en la imagen principal
    selectedimg(img) {
      this.imgSelected = img.xlg;
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    downloadFreightTable(){
      window.open('https://proplat-public-images.s3.amazonaws.com/PROPLAT-TABULADOR.pdf','_blank')
    }
  },
  computed: {
    refresh() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refresh: function() {
      this.articleDetail();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
        this.articleDetail();
      }
    },
  },
};
